<template>
    <div class="complete-userName">
      <div class="cu-top">
        <p>欢迎加入秘塔法务</p>
      </div>
      <el-form ref="userInfoRef" :rules="inputRules"  :model="userInfo" label-width="0" class="text-inputs-container">
        <p class="cu-name-tip">请修改用户名，以帮您提高办公效率：</p>
        <el-form-item prop="userName">
          <el-input class="text-input" placeholder="请输入您的姓名" v-model="userInfo.userName"></el-input>
        </el-form-item>
        <div class="submit-btn">
          <el-button data-tag-id="user-phone-login" @click="saveUserInfo()" type="primary"
                     class="text-input"
          >
            确认
          </el-button>

        </div>
      </el-form>
    </div>
</template>

<script>
import MetaGoLogo from "../components/universalUI/MetaGoLogo";
import {colorLog} from "../util";
import {getMyInfo, updateUserInfo, updateUserNickName} from "../api/api";
import {ElMessage} from "element-plus";

export default {
  name: "CompleteUserName",
  components: {MetaGoLogo},
  data() {
    return {
      inputRules: {
        userName: [
          {
            required: true, validator: (rule, value, callback) => {
              if (!value || /\s/.test(value)) {
                callback(new Error('姓名不能为空或包含空格'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
      },
      userInfo: {
        userName:''
      }
    };
  },
  created(){
    try{
      getMyInfo().then(res => {
        if(res.data.code == 200 || res.data.code == 0){
          this.userInfo = res.data.userInfo;
        }else{
          this.userInfo = {
            userName:''
          }
        }
      })

    }catch (e){
      this.userInfo = {
        userName:''
      }
    }
  },
  methods:{

    saveUserInfo(){
      this.$refs.userInfoRef.validate().then(()=>{
        //输入的姓名字符串合法
        return updateUserNickName(this.userInfo.userName);
      }).then(async res => {
        if(res.data.code == 0 || res.data.code == 200){
          ElMessage.success("设置成功");
          this.$emit('onSuccess',this.userInfo);

          let res = await getMyInfo();
          if(res.data.code != 0) return;
          //重置当前保存的用户信息
          sessionStorage.setItem('userInfo', JSON.stringify(res.data.userInfo));

        }
      });
    },
  }
}
</script>

<style scoped>
.complete-userName{
  --userInfo-compoent-height:21em;
  --userInfo-compoent-width:38em;
  --userInfo-compoent-padding:2em;
  --userInfo-compoent-top-bar-height:3em;
  font-size: 10px;

  background-color: white;
  box-shadow: rgb(60 64 67 / 15%) 0 1px 5px 2px;
  z-index: 1;
  width: var(--userInfo-compoent-width);
  min-height: var(--userInfo-compoent-height);
  border-radius: 5px;
  position: relative;
  display: inline-block;

  box-shadow: 0 0 3px 2px var(--gray-2);
}
.text-inputs-container{
  position: absolute;
  top:5em;
  left: 0;
  bottom:0;
  right:0;

  height: fit-content;
  width:calc(var(--userInfo-compoent-width) * .8);
  margin: auto;
}
.cu-top{
  margin-top: 1.8em;
}
.cu-top>p{
  font-size:1.125rem;
  font-weight: bold;
}
.cu-name-tip{
  font-weight: bold;
  text-align: left;
}
.cu-top >>> .metago-logo-img{
  width:calc(var(--userInfo-compoent-width) * .6);
}
.submit-btn{
  text-align: right;
}
</style>
